import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import Sidebar from 'components/sidebar';
import Hidden from 'components/hidden';
import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Nailing Our Pitch`}</h1>
    <p>{`First - a quick shoutout to Bruce who took the "Kickoff" plan from Day 1 and turned it into a sweet Notion template (`}<a parentName="p" {...{
        "href": "https://www.notion.so/Project-Kickoff-TITLE-a28759d5f0174be3970dead61ae7402d"
      }}>{`get it here`}</a>{`). You can click "duplicate" in the top right corner, edit, and use it for your projects.  `}</p>
    <p>{`Hiya blush. Today was a fun day. Lots to share.`}</p>
    <p><strong parentName="p">{`Random happenings:`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`I did an advisory call with a startup that's trying to build "Shopify for video services" (e.g. a storefront builder for a yoga teacher who charges for video classes). This could be big, but I'm skeptical the team is committed/cohesive enough to pull this off.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`found out that a startup I'm investing in is taking-the-fuck-off. It's shopify for shopkeepers in India. I talked to the guy a month ago when he first built the app. In the last 30 days, over 400k stores have been created . That's the fastest growing company I've ever personally seen.`}</p>
      </li>
    </ul>
    <p>{`But... a bit of bad news. Now that it started growing like crazy, tons of people now want to invest in him (including the founder of stripe, and some big VCs). So my check size got squished down to make room for everyone.`}</p>
    <p><strong parentName="p">{`Today's OBT:`}</strong><br parentName="p"></br>{`
`}{`I just did a call with Ben, my secret weapon/right hand man who is helping me with this project.`}</p>
    <p>{`We agreed that today's ONE BIG THING (OBT) is to nail the pitch deck and story - so we can go start asking people to invest in the fund.`}</p>
    <p>{`So that will be the execution deep dive for today. How to create a simple pitch deck in a few hours.`}</p>
    <p>{`OK when it comes to creating a Pitch - most people jump right into making slides. That's a mistake imo.`}</p>
    <p>{`You gotta start with the ending you want: Somebody doing a wire transfer to send their hard earned money into your bank account.`}</p>
    <p>{`Why would someone do this? What's in it for them? What questions/objections do you have to address? How do they know they can trust you?`}</p>
    <p>{`I made a little screencast showing how we went from an outline ---> rough draft → Final draft.`}</p>
    <div style={{
      "position": "relative",
      "paddingBottom": "62.5%",
      "height": "0"
    }}>
  <iframe src='https://www.loom.com/embed/2e4399661f88412aba0b36f6372b003d' frameBorder='0' webkitallowfullscreen mozallowfullscreen allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe>
    </div>
    <p>{`​`}<a parentName="p" {...{
        "href": "https://www.loom.com/share/2e4399661f88412aba0b36f6372b003d%E2%80%8B"
      }}>{`https://www.loom.com/share/2e4399661f88412aba0b36f6372b003d​`}</a></p>
    <Hidden label="Show Password" text="allaccesspass" mdxType="Hidden" />
    <p><strong parentName="p">{`Wait - did he say screencast? That's right - we're going MULTIMEDIA in this bitch! (Heads up: it's a ~15-minute watch)`}</strong></p>
    <p><strong parentName="p">{`Sidebar:`}</strong>{` ^ the founder of loom (my friend Shahed) was in my masterminds early on when they were actually a totally different service. Now they are like a $350M+ company. I had the opportunity to invest in the seed round but passed. The guys were smart but they seemed young and the team was a little dysfunctional. Whoops! I was way wrong.`}</p>
    <p><strong parentName="p">{`And here are links to the stuff you see in the video:`}</strong></p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.google.com/presentation/d/1BNpkDB7KdYUrNZFFf00dmJI3iDsFwkFw1AL7jwKILBE/edit?usp=sharing"
        }}>{`The Dummy Deck template`}</a>{` (make your own copy!)`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.google.com/presentation/d/1nldjnClAOog1LFUB83HveHBOQ4W9XqX7JggaeAQKuj8/edit?usp=sharing"
        }}>{`The Rough Draft deck`}</a>{` we made ˜1-2hrs`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docsend.com/view/k45t98s7a6pj34ib"
        }}>{`The Final Version`}</a>{` We Made (another ˜4 hrs)`}</li>
    </ol>
    <p><strong parentName="p">{`I know most of you on this email list aren't making a pitch deck right now. So bookmark this for when the time comes that you need investors to back your projects. At the same time, I also believe most of these principles apply to making decks and telling stories to close any type of sale, too.`}</strong></p>
    <p><strong parentName="p">{`I'm hoping you can learn from what I'm working on and apply it to something you’re trying to accomplish now or in the near future - even if it's not a perfect match with the problem you're solving in your day-to-day.`}</strong></p>
    <p><strong parentName="p">{`Let me know if that's what's happening, or if I'm way off base. We're only on day 3, so still learning & iterating here!`}</strong></p>
    <p>{`cheers,`}</p>
    <p>{`shaan`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://www.notion.so/Project-Kickoff-TITLE-a28759d5f0174be3970dead61ae7402d',
        label: 'Notion Project Kickoff Template'
      }, {
        url: 'https://docs.google.com/presentation/d/1BNpkDB7KdYUrNZFFf00dmJI3iDsFwkFw1AL7jwKILBE/edit?usp=sharing',
        label: 'Dummy Deck Template'
      }, {
        url: 'https://docs.google.com/presentation/d/1nldjnClAOog1LFUB83HveHBOQ4W9XqX7JggaeAQKuj8/edit?usp=sharing',
        label: 'The Rough Draft Deck'
      }, {
        url: 'https://docsend.com/view/k45t98s7a6pj34ib',
        label: 'The Final Version'
      }]
    }, {
      title: LinkLabel.Multimedia,
      links: [{
        url: 'https://www.loom.com/share/2e4399661f88412aba0b36f6372b003d%E2%80%8B',
        label: 'Pitch Decks Loom'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.making_the_sales_map.label,
      slug: slugify(postMap.making_the_sales_map.slug)
    }} nextButton={{
      text: postMap.the_taste_of_rejection.label,
      slug: slugify(postMap.the_taste_of_rejection.slug)
    }} mdxType="PostFooter" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      